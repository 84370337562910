/** Easy Tree style */
.easy-tree {
    min-height: 20px;
    color: #4C4C4C;
    border: none;
    border-top: none;
}

.easy-tree ul {
    padding-inline-start: 15px;
}

.easy-tree > ul {
    padding-left: 15px;
}

.easy-tree > ul > li.only_info > div > div.name_block > a{
    font-weight: 600;
    padding-top: 3.5px;
    padding-bottom: 3.5px;
}

.easy-tree ol{
    padding-left: 5px;
}

.easy-tree li {
    list-style-type: none;
    margin: 0;
    position: relative;
}

.easy-tree li div{    
    display: inline-flex;
    align-items: center;
}

.easy-tree ol > li{
    padding-left: 10px;
}

.easy-tree ol > li > a{
    font-size: 80%;
    display: block;
}

.easy-tree ol > li > a.active{
    background-color: #F4F4F4;
}

.easy-tree li > ol{
    padding-left: 15px;
}

.easy-tree li > div > div.name_block {
    /*border: 1px solid #4C4C4C;*/
    border-radius: 3px;
    padding: 2px 5px;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
}

.easy-tree li > div > label{
    margin-left: 2px;
    margin-bottom: 0px;
}

.easy-tree li.parent_li > div > div.name_block {
    cursor: pointer;
}

.easy-tree li > div > div.name_block > i {
    font-size: 18px;
    max-height: 18px;
    /*display: contents;*/
}

.easy-tree li > div > div.name_block:hover, .easy-tree li > div > div.name_block:hover + ul li div {
    background: #e6e6e6;
    color: #4C4C4C;
}

.easy-tree li > div > div.name_block:hover + ul li.li_selected div div {
    background: #e6e6e6;
    color: #4C4C4C;
}

.easy-tree li > div > div.name_block > a {
    color: #4C4C4C;
    text-decoration: none;
    padding-left: 5px;
}

.easy-tree li.li_selected > div > div.name_block, .easy-tree li.li_selected > div > div.name_block > a {
    background: #e6e6e6;
    color: #4C4C4C;
}

.easy-tree li.li_selected > div > div.name_block:hover, .easy-tree li.li_selected > div > div.name_block:hover > a {
    background: #e6e6e6;
    color: #4C4C4C;
}

.easy-tree .easy-tree-toolbar > div, .easy-tree .easy-tree-bottom-toolbar > div{
    display: inline-block;
    margin-left: 2px;
    margin-top: 2px;
}

.easy-tree .easy-tree-toolbar > div > button, .easy-tree .easy-tree-bottom-toolbar > div > button {
    border-radius: 3px;
    font-size: small;
}

#createImput .input-group, #editImput .input-group {
    display: table;
    margin-left: 5px;
    margin-right: 5px;
}

#createImput .input-group.tree-tag input, #editImput .input-group.tree-tag input {
    border-radius: 0;
    border-top-left-radius: 3px;
    border-bottom: 0px;
}

#createImput .input-group.tree-name input, #editImput .input-group.tree-name input {
    border-radius: 0;
    border-bottom-left-radius: 3px;
    border-top: 0px;
}

#createImput .input-group button, #editImput .input-group button {
    border-radius: 0;
}

#createImput .input-group.tree-tag button:last-child, #editImput .input-group.tree-tag button:last-child {
    border-radius: 0;
    border-top-right-radius: 3px;
    min-width: 100px;
}

#createImput .input-group.tree-name button:last-child, #editImput .input-group.tree-name button:last-child {
    border-radius: 0;
    border-bottom-right-radius: 3px;
    min-width: 100px;
}

.easy-tree .btn.disabled{
    pointer-events: none;
}

.easy-tree .inputError{
    border: #FF0000 1px solid;
}

.easy-tree .easy-tree-toolbar{
    margin-left: 10px;
}

.easy-tree .easy-tree-bottom-toolbar .btn, .easy-tree .easy-tree-toolbar .btn{
    padding: 0px 5px;
}

.easy-tree .tooltip{
    text-align: center;
    margin: 0 auto;
}

.easy-tree li div.tooltip-inner{
    display: block;
}

.easy-tree i[class^="mdi mdi-18px mdi-menu-"] {
    margin-bottom: 5px;
}